import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import axios from "axios";
import Assisthero from "../assets/services/assisthero.svg";
import Locationimg from "../assets/services/locationimg.svg";
import Circle from "../assets/services/circle.svg";
import CircleBlue from "../assets/services/circleblue.svg";
import cornerright from "../assets/services/cornerright.svg";
import arrowup from "../assets/services/arrowup.svg";
// import ReactFlagsSelect from "react-flags-select";
import icon from "../assets/chevron-up.svg";
import Layout from "components/Layout";
import Seo from "components/seo";
import http from "core/utils/http";
import { endpoints } from "core/utils/endpoints";
import Loader from "components/Loader";

const Translate = () => {
  const {
    register,
    control,

    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loader, setLoader] = useState(false);
  const [response, setResponse] = useState("");

  const [activeSectionIndex, setActiveSectionIndex] = useState(0);

  useEffect(() => {
    anchorLayout();
    document.addEventListener("scroll", anchorScroll);
    return () => {
      document.removeEventListener("scroll", anchorScroll);
    };
  });

  const anchorScroll = () => {
    if (document.getElementsByClassName("layout-anchor").length > 0) {
      const navHeight = document.querySelector(".sticky")?.offsetHeight;
      const scrollPos =
        document.documentElement.scrollTop || document.body.scrollTop;
      const ulList = document.querySelectorAll(
        ".anchor-navigation ul > li > a"
      );
      Array.prototype.forEach.call(ulList, function (el) {
        const index = el.getAttribute("index");
        const refElement = document?.getElementById(index);
        const currLink = el;
        const elemPos = refElement?.offsetTop - navHeight;
        if (
          currLink.classList.contains("customAnchor")
            ? scrollPos >= refElement?.offsetTop
            : scrollPos >= elemPos
        ) {
          Array.prototype.forEach.call(ulList, function (el2) {
            el2.classList?.remove("active");
          });
          el.classList?.add("active");
          setActiveSectionIndex(Number(index));
        }
      });
    }
  };

  const anchorLayout = () => {
    if (document.getElementsByClassName("layout-anchor").length > 0) {
      var navHeight = document.getElementsByClassName("sticky")[0].offsetHeight;

      var ulList = document.querySelectorAll(".anchor-navigation ul > li > a");
      Array.prototype.forEach.call(ulList, function (el, i) {
        el.addEventListener(
          "click",
          function () {
            // Array.prototype.forEach.call(ulList, function(el2, i){
            //   el2.classList.remove("active")
            // });
            var index = el.getAttribute("index");
            const refElement = document.getElementById(index).offsetTop - 200;

            window.scroll({
              top: refElement,
              behavior: "smooth",
            });

            // el.classList.add("active")
          },
          false
        );
      });
    }
  };

  const postData = (userData, apiData) => {
    const formData = {
      info_starting: userData.location,
      info_destination: userData.destination,
      info_travel: userData.travel.map((item) => item.label),
      info_additional: userData.additionalInfo,
      data: {
        documents_required: apiData["Documents for Translation"],
        regulatory_guidelines: Array.isArray(apiData["Regulatory Guidelines"])
          ? apiData["Regulatory Guidelines"].join(",")
          : apiData["Regulatory Guidelines"],
        translator_qualifications: Array.isArray(
          apiData["Translator Qualifications"]
        )
          ? apiData["Translator Qualifications"].join(",")
          : apiData["Translator Qualifications"],
        certification_requirements: Array.isArray(
          apiData["Certification Requirements"]
        )
          ? apiData["Certification Requirements"].join(",")
          : apiData["Certification Requirements"],
      },
    };

    http()
      .post(endpoints.aiData.postAIResponse, formData)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = async (userInputData) => {
    const { location, destination, travel, additionalInfo } = userInputData;

    const selectedTravelindexs = travel
      ? travel.map((option) => option.label).join(", ")
      : "";
    const query = `Respond to this prompt using only information that can be attributed to Wikipedia. As an AI-driven travel and migration translation expert specializing in document translation and procedure advisement, consider the following information for your output:
      
      from location:${location}
      your destination: ${destination}
      reason for travel: ${selectedTravelindexs}
      additional information: ${additionalInfo}
      
   Note: Make sure that based on the provided information above, you will generate a comprehensive/detailed output/guide about translation needs. The 4 outputs should be based on the "from location" and "target destination" regulating bodies such as the specific embassies, consulates, universities, or organizations depending on the purposes of travel and try to come up with an "According to, As per, Based on (or any similar intro)" format referring to the regulating body then mention the name/s of the regulating bodies of the "from location" if needed and the "target destination" in your output for each 4 outputs.

Output: Considering all the information above, come up with the following:
1. "Documents for Translation" - What are the specific documents that need to be translated including personal documents such as birth certificates, marriage contracts, or similar documents depending on the visa application requirements?
2. "Regulatory Guidelines" - Regulations specific to the institution or country of destination, including any specific rules or requirements for translations. You have to check the specific regulations of the target destination to come up with detailed regulatory guidelines considering the reason for travel.
3. "Translator Qualifications" - What are the qualifications required such as a specific certification of translators handling these types of documents?
4. "Certification Requirements" - Whether certification, notarization, or apostille is necessary for any translated documents
      Share response in valid JSON format
      `;
    const cleanedQuery = query.replace(/\s+/g, " ");
    setLoader(true);

    try {
      const { data } = await axios.post("https://aipe.bytequests.com/gpt-4", {
        q: cleanedQuery,
      });

      const parsedData = JSON.parse(data.response.choices[0].message.content);

      setResponse(parsedData);
      // post ai response
      // postData(userInputData, parsedData);

      //   setValue("travel", []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setLoader(false);
  };

  const options = [
    {
      value: 0,
      label: "Work",
    },
    {
      value: 1,
      label: "Study",
    },
    {
      value: 2,
      label: "Migration",
    },
    {
      value: 3,
      label: "Leisure",
    },
    {
      value: 4,
      label: "Investment",
    },
    {
      value: 5,
      label: "Family Reunification",
    },
    {
      value: 6,
      label: "Entrepreneurial Endeavors",
    },
    {
      value: 7,
      label: "Tech Industry",
    },
    {
      value: 8,
      label: "Religious Activities",
    },
    {
      value: 9,
      label: "Study Exchange",
    },
    {
      value: 10,
      label: "Victim Support",
    },
    {
      value: 11,
      label: "Startup Ventures",
    },
    {
      value: 12,
      label: "Others",
    },
  ];

  return (
    <Layout>
      <Seo
        title="AI-Assist | FormalTranslations.com"
        description="Get all the information on translating official documents, tailored for relocation, citizenship, university admissions, and other needs, all powered by OpenAI's ChatGPT."
      />
      <div className="w-full max-w-[1440px] mx-auto">
        <div className="w-11/12 lg:flex mt-[100px] gap-6 lg:items-start items-center justify-between mx-auto text-[#0a1612] py-[5rem] 500px:px-6">
          <div className="-mt-5">
            <h1 className="lg:text-[45px] text-[40px] font-bold font-primary md:pr-8">
              Seamless Translations for Global Endeavors
            </h1>
            <p className="pt-4 pb-12">
              Explore comprehensive insights on translating official documents
              using AI.
            </p>

            <div className="hidden lg:flex items-center justify-start bg-contain bg-locationsImage object-cover mt-24">
              <img
                src={Assisthero}
                alt="avatar"
                className="lg:block hidden -mt-24"
              />
            </div>
          </div>
          <div className="w-full lg:mt-0 mt-10 flex items-center justify-center 500px:px-5">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="w-full text-black flex flex-col gap-[10px]"
            >
              <div className="bg-[#FFF8DF] w-full flex gap-4 p-4 items-center rounded-md ">
                <div className="flex flex-col gap-1 items-center">
                  <img src={Locationimg} alt="location" />
                  <img src={Circle} alt="circle" />
                  <img src={Circle} alt="circle" />
                  <img src={Circle} alt="circle" />
                  <img src={CircleBlue} alt="circle" />
                  <img src={cornerright} alt="" />
                </div>
                <div className="0px:w-full 500px:w-[356px] flex flex-col gap-3">
                  <input
                    type="text"
                    {...register("location", { required: true })}
                    name="location"
                    placeholder="Choose your starting point* "
                    className="w-full border  border-[#BBBBBB] rounded-md text-sm font-normal leading-5 px-2 py-3 placeholder-[#DDDDDD]"
                  />
                  {errors.location && (
                    <p className="text-[#FF0000]">Location is required</p>
                  )}
                  <input
                    type="text"
                    name="destination"
                    {...register("destination", { required: true })}
                    placeholder="Choose Destination*"
                    className="w-full border  border-[#BBBBBB]  rounded-md text-sm font-normal leading-5 px-2 py-3 placeholder-[#DDDDDD]"
                  />
                  {errors.destination && (
                    <p className="text-[#FF0000]">Destination is required</p>
                  )}
                </div>
                <div>
                  <img src={arrowup} alt="" />
                </div>
              </div>
              <div className="py-4">
                <h2 className="text-[#262626] text-base font-opensans font-normal leading-5 pb-2">
                  Translation Objective?
                  <span className="text-[#FF0000]">*</span>
                </h2>

                <Controller
                  name="travel"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={options}
                      isClearable={true}
                      isMulti
                    />
                  )}
                />
                {errors.travel && (
                  <p role="alert" className="text-[#FF0000]">
                    This field is required
                  </p>
                )}
              </div>
              <p className="text-[#262626] text-base font-opensans font-normal leading-5">
                Additional information
              </p>
              <textarea
                placeholder="What specific guidance are you seeking? The more details you provide, the better we can assist you. (Optional)"
                name="additionalInfo"
                {...register("additionalInfo", { required: true })}
                className="w-full p-3 h-36 border border-[#BBBBBB] py-3 px-2 rounded-md text-base placeholder-[#DDDDDD]  resize-none"
              />
              {errors.additionalInfo && (
                <p role="alert" className="text-[#FF0000]">
                  This field is required
                </p>
              )}

              <div className="flex flex-col items-center font-opensans">
                <p className="text-sm font-normal leading-5 text-[#91979F]">
                  *Disclaimer: Powered by OpenAI's ChatGPT
                </p>

                {loader ? (
                  <button
                    disabled
                    className="bg-[#00173a] mt-3 rounded-lg leading-7 text-base font-normal  px-[50px] py-[14px] text-white"
                  >
                    <Loader />
                  </button>
                ) : (
                  <button
                    // type="submit"
                    className="bg-[#00173a] mt-3 rounded-lg leading-7 text-base font-normal  px-8 text-white py-[10px] "
                  >
                    Analyze
                  </button>
                )}
                <p className=" mt-3 text-sm font-normal leading-5 text-[#91979F]">
                  *By clicking analyze, you agree to
                </p>
                <p className="text-sm font-normal leading-5 text-[#91979F]">
                  Formal Translation's{" "}
                  <span className="text-[#006BFF]">Privacy Policy.</span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>

      {response && (
        <div className="w-full bg-[#F8FBFF]">
          <div className="w-11/12 mx-auto parent-div-scroll bg-cover bg-no-repeat pt-12 flex-layout layout-anchor">
            <div className="lg:flex  gap-6 lg:items-start justify-between max-w-[1440px] mx-auto pb-6 text-black">
              <div className="w-1/2 sticky top-40 lg:block hidden lg:px-6 md:px-16 px-4">
                <div
                  id="scrollableElementId"
                  className="fixedElement anchor-navigation"
                >
                  <ul>
                    {Object.keys(response).map((key, index) => (
                      <li key={index}>
                        <a
                          index={index}
                          className={
                            index === activeSectionIndex
                              ? "customAnchor active scrollTo" + index
                              : "scrollTo" + index
                          }
                        >
                          <p className="text-[#5C636D] leading-6 text-[15px] font-sans ">
                            {key}
                          </p>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div
                className="lg:w-1/2 w-full lg:px-10 md:px-16 500px:px-4"
                id="anchorTagsColor "
              >
                {Object.entries(response).map(([key, value], index) => (
                  <div
                    className={`mb-12 p-4 bg-white ${
                      index === activeSectionIndex
                        ? "border border-[#00173a] rounded-lg"
                        : ""
                    }`}
                    id={index}
                    key={index}
                    style={{
                      boxShadow: "0px 8px 34px 0px rgba(0, 0, 0, 0.10)",
                    }}
                  >
                    <h2 className="text-base font-semibold  text-[#042c7f]  scroll-mt-20 md:w-full sticky 2xl:w-max-w-max">
                      {key}
                    </h2>

                    <div className="text-[16px] font-primary text-[#0a2641] list_disc_css leading-loose pt-4 w-full md:w-full">
                      {Array.isArray(value) ? (
                        <ul>
                          {value.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}{" "}
                        </ul>
                      ) : (
                        <p>{value}</p>
                      )}
                    </div>
                  </div>
                ))}
                <div className="flex flex-col gap-2 items-center justify-center w-52 mx-auto">
                  <button
                    type="button"
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                    className="text-[#00173a] font-opensans font-bold text-sm flex flex-col items-center"
                  >
                    <img src={icon} alt="icon" />
                    <span className="mt-2 font-bold font-opensans text-sm">
                      Back to Top
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};
export default Translate;
